<template>
  <v-dialog v-model="showModal" max-width="1200px" persistent>
    <v-card>
      <div v-if="courierCode == 1">
        <v-card-subtitle class="pt-3 pb-0 my-0">
          <v-row no-gutters> </v-row>
        </v-card-subtitle>
        <v-card class="mx-4 my-1" outlined elevation="0">
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">出荷日:</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <div class="text-value-div text-color">
                <label v-if="memberInfo">{{ scheduledShippingDate }}</label>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">総重量:</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div text-color">
                <label v-if="memberInfo">{{ memberInfo.total_weight }}kg</label>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="3">
              <v-card-text class="text-start text-sm-end align-self-center">担当者名: </v-card-text>
            </v-col>
            <v-col class="d-flex pb-0 border-right" cols="3">
              <div class="text-value-div text-color">
                <label>{{ personInCharge }}</label>
              </div>
            </v-col>
            <v-col class="d-flex pb-0" cols="3">
              <v-card-text class="text-start text-sm-end align-self-center">TEL:</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="3">
              <div class="text-value-div text-color">
                <label>{{ tel }}</label>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">会社名: </v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div text-color">
                <label>{{ shipToCompanyName }}</label>
              </div>
            </v-col>
          </v-row>

          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">Address Line 1:</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div text-color">
                <label>{{ addr1 }}</label>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">Address Line 2:</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div text-color">
                <label>{{ addr2 }}</label>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">郵便番号/都市名/州名:</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div">
                <label
                  ><span class="text-color">{{ zipCode }}</span> / <span class="text-color">{{ city }}</span> /
                  <span class="text-color">{{ computedStateName }}</span></label
                >
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">国名:</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div text-color">
                <label v-if="memberInfo">{{ memberInfo.country_name }}</label>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-card-subtitle class="pt-3 pb-0 my-0">
          <v-row no-gutters> </v-row>
        </v-card-subtitle>
        <v-card class="mx-4 my-1" outlined elevation="0">
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">Carton数:</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div text-color">
                <label v-if="memberInfo">{{ memberInfo.total_cartons }}</label>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="3">
              <v-card-text class="text-start text-sm-end align-self-center">サービスタイプ:</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="3">
              <div class="text-value-div text-color">
                <label>{{
                  (courierDeliveryTypes[courierCode].find((row) => row.value1 === deliveryType) || {}).value5
                }}</label>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">Reference(Invoice番号)</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div text-color">
                <label v-if="memberInfo">{{ memberInfo.reference }}</label>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">DESC1:</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div text-color">
                <label v-if="memberInfo">{{ memberInfo.description }}</label>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">DESC2:</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div text-color">
                <!-- <label v-if="memberInfo">{{
                  memberInfo.shipping_description
                }}</label> -->
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">DESC3:</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div text-color"></div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">DESC4:</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div text-color"></div>
            </v-col>
          </v-row>
        </v-card>
        <v-card-subtitle class="pt-3 pb-0 my-0">
          <v-row no-gutters> </v-row>
        </v-card-subtitle>
        <v-card class="mx-4 my-1" outlined elevation="0">
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">Country Manufacture:</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div">
                <label><span class="text-color">CN</span> </label>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="3">
              <v-card-text class="text-start text-sm-end align-self-center">補償価額(CARRIAGE VALUE):</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0 border-right" cols="3">
              <div class="text-value-div text-color">
                <label v-if="insurancePrice"
                  >{{ insurancePrice.commaString() }}{{ courierCode == 1 ? 'USD' : 'JPY' }}</label
                >
              </div>
            </v-col>
            <v-col class="d-flex pb-0" cols="3">
              <v-card-text class="text-start text-sm-end align-self-center">送料(T/C)</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="3">
              <div class="text-value-div text-color">
                <label v-if="shipTo">{{ shipTo.shipping_fee_paying_account }}</label>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="d-flex pb-0" cols="3">
              <v-card-text class="text-start text-sm-end align-self-center">Invoice総額(CUSTOMS VALUE):</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0 border-right" cols="3">
              <div class="text-value-div text-color">
                <label v-if="memberInfo">{{ memberInfo.total_price.commaString() }}USD</label>
              </div>
            </v-col>
            <v-col class="d-flex pb-0" cols="3">
              <v-card-text class="text-start text-sm-end align-self-center">関税・諸経費(D/T)</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="3">
              <div class="text-value-div text-color">
                <label v-if="shipTo">{{ shipTo.tax_paying_account }}</label>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <div v-if="courierCode == 2">
        <v-card-subtitle class="pt-3 pb-0 my-0">
          <v-row no-gutters> </v-row>
        </v-card-subtitle>
        <v-card class="mx-4 my-1" outlined elevation="0">
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">箱数:</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div text-color">
                <label v-if="memberInfo">{{ memberInfo.total_cartons }}</label>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">総重量:</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div text-color">
                <label v-if="memberInfo">{{ memberInfo.total_weight }}kg</label>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">出荷日:</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <div class="text-value-div text-color">
                <label v-if="memberInfo">{{ scheduledShippingDate }}</label>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-card-subtitle class="pt-3 pb-0 my-0">
          <v-row no-gutters> </v-row>
        </v-card-subtitle>
        <v-card class="mx-4 my-1" outlined elevation="0">
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">担当者名: </v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div text-color">
                <label>{{ personInCharge }}</label>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">電話番号:</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div text-color">
                <label>{{ tel }}</label>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">会社名: </v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div text-color">
                <label>{{ shipToCompanyName }}</label>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">Address Line 2:</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div text-color">
                <label>{{ addr2 }}</label>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">Address Line 1:</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div text-color">
                <label>{{ addr1 }}</label>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">郵便番号/都市名/州名:</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div">
                <span class="text-color">{{ zipCode }}</span> / <span class="text-color">{{ city }}</span> /
                <span class="text-color">{{ computedStateName }}</span>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">国名:</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div text-color">
                <label v-if="memberInfo">{{ memberInfo.country_name }}</label>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-card-subtitle class="pt-3 pb-0 my-0">
          <v-row no-gutters> </v-row>
        </v-card-subtitle>
        <v-card class="mx-4 my-1" outlined elevation="0">
          <v-row no-gutters>
            <v-col class="d-flex pb-0" cols="3">
              <v-card-text class="text-start text-sm-end align-self-center">サービスタイプ:</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="3">
              <div class="text-value-div text-color">
                <label>{{
                  (courierDeliveryTypes[courierCode].find((row) => row.value1 === deliveryType) || {}).name
                }}</label>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-card-subtitle class="pt-3 pb-0 my-0">
          <v-row no-gutters> </v-row>
        </v-card-subtitle>
        <v-card class="mx-4 my-1" outlined elevation="0">
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">請求先:</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div">
                <label v-if="shipTo"
                  ><span class="text-color">{{ uspShippingFeePayer }}</span> /
                  <span class="text-color">{{ upsTaxPayerAcct }}</span> /
                  <span class="text-color">{{ shipTo.courier_account }}</span></label
                >
              </div>
            </v-col>
          </v-row>

          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">Description</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div text-color">
                <label v-if="shipTo">{{ shipTo.description }}</label>
              </div>
            </v-col>
          </v-row>

          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">Invoice No</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div text-color">
                <!-- <label>{{ upsInvoiceNo }}</label> -->
                <label v-if="memberInfo">{{ memberInfo.reference }}</label>
              </div>
            </v-col>
          </v-row>

          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">Purchase No</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div text-color">
                <label v-if="memberInfo">{{ memberInfo.po_number_list }}</label>
              </div>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">{{
                `申告価格(補償金額)\r\n※UPS.comで確認`
              }}</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div text-color">
                <label v-if="insurancePrice"
                  >{{ insurancePrice.commaString() }}{{ courierCode == 1 ? 'USD' : 'JPY' }}</label
                >
              </div>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <div v-if="courierCode == 3">
        <!-- クーリエ情報 -->
        <v-card-subtitle class="py-0 my-0 pt-3">送付先(Shipment to)</v-card-subtitle>
        <v-card class="mx-4 my-1" outlined elevation="0">
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">会社名: </v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div text-color">
                <label>{{ shipToCompanyName }}</label>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">担当者名: </v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div text-color">
                <label>{{ personInCharge }}</label>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">Address Line 1:</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div text-color">
                <label>{{ addr1 }}</label>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">Address Line 2:</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div text-color">
                <label>{{ addr2 }}</label>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">郵便番号/都市名/州名:</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div">
                <label
                  ><span class="text-color">{{ zipCode }}</span> / <span class="text-color">{{ city }}</span> /
                  <span class="text-color">{{ computedStateName }}</span></label
                >
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">国名:</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div text-color">
                <label v-if="memberInfo">{{ memberInfo.country_name }}</label>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="border-bottom">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">電話番号:</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <div class="text-value-div text-color">
                <label>{{ tel }}</label>
              </div>
            </v-col>
          </v-row>
        </v-card>

        <!-- 住所 -->
        <v-card-subtitle class="pt-3 pb-0 my-0">
          <v-row no-gutters>
            <v-col class="d-flex pb-0" cols="6">
              <v-card-text class="text-start text-sm-end align-self-center text-left"
                >詳細(Shipment Details)</v-card-text
              >
            </v-col>
          </v-row>
        </v-card-subtitle>
        <v-card class="mx-4 my-1" outlined elevation="0">
          <v-form ref="form" v-model="validate" lazy-validation>
            <v-row no-gutters class="border-bottom">
              <v-col class="d-flex pb-0" cols="3">
                <v-card-text class="text-start text-sm-end align-self-center">サービスタイプ:</v-card-text>
              </v-col>
              <v-col class="d-flex pb-0" cols="3">
                <div class="align-self-center text-color">
                  <label>{{
                    (courierDeliveryTypes[courierCode].find((row) => row.value1 === deliveryType) || {}).name
                  }}</label>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="border-bottom">
              <v-col class="d-flex pb-0" cols="3">
                <v-card-text class="text-start text-sm-end align-self-center">送料支払人：</v-card-text>
              </v-col>
              <v-col class="d-flex pb-0" cols="3">
                <div class="text-value-div text-color">
                  <label v-if="shipTo">{{ shipTo.shipping_fee_paying_account }}</label>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="border-bottom">
              <v-col class="d-flex pb-0" cols="3">
                <v-card-text class="text-start text-sm-end align-self-center">関税支払人:</v-card-text>
              </v-col>
              <v-col class="d-flex pb-0" cols="3">
                <div class="text-value-div text-color">
                  <label v-if="shipTo">{{ shipTo.tax_paying_account }}</label>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="border-bottom">
              <v-col class="d-flex pb-0" cols="3">
                <v-card-text class="text-start text-sm-end align-self-center">Reference：</v-card-text>
              </v-col>
              <v-col class="d-flex pb-0" cols="3">
                <div class="text-value-div text-color">
                  <label v-if="memberInfo">{{ memberInfo.reference }}</label>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="border-bottom">
              <v-col class="d-flex pb-0" cols="3">
                <v-card-text class="text-start text-sm-end align-self-center">Invoice総額(Declared Value)</v-card-text>
              </v-col>
              <v-col class="d-flex pb-0" cols="3">
                <div class="text-value-div text-color">
                  <label v-if="memberInfo">{{ memberInfo.total_price.commaString() }}USD</label>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="border-bottom">
              <v-col class="d-flex pb-0" cols="3">
                <v-card-text class="text-start text-sm-end align-self-center">Content(Description)</v-card-text>
              </v-col>
              <v-col class="d-flex pb-0" cols="3">
                <div class="text-value-div text-color">
                  <label v-if="memberInfo">{{ memberInfo.description }}</label>
                </div>
              </v-col>
            </v-row>

            <v-row no-gutters class="border-bottom">
              <v-col class="d-flex pb-0" cols="3">
                <v-card-text class="text-start text-sm-end align-self-center"
                  >補償(Declared Value for Insurance):</v-card-text
                >
              </v-col>
              <v-col class="d-flex pb-0" cols="3">
                <div class="text-value-div text-color">
                  <label v-if="insurancePrice"
                    >{{ insurancePrice.commaString() }}{{ courierCode == 1 ? 'USD' : 'JPY' }}</label
                  >
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="border-bottom">
              <v-col class="d-flex pb-0" cols="3">
                <v-card-text class="text-start text-sm-end align-self-center">総重量(Total Weight):</v-card-text>
              </v-col>
              <v-col class="d-flex pb-0 border-right" cols="3">
                <div class="text-value-div text-color">
                  <label v-if="memberInfo">{{ memberInfo.total_weight }}kg</label>
                </div>
              </v-col>
              <v-col class="d-flex pb-0" cols="3">
                <v-card-text class="text-start text-sm-end align-self-center">Carton数(Pieces):</v-card-text>
              </v-col>
              <v-col class="d-flex pb-0 border-right" cols="3">
                <div class="text-value-div text-color">
                  <label v-if="memberInfo">{{ memberInfo.total_cartons }}</label>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="border-bottom">
              <v-col class="d-flex pb-0" cols="3">
                <v-card-text class="text-start text-sm-end align-self-center">VAT No:</v-card-text>
              </v-col>
              <v-col class="d-flex pb-0 border-right" cols="3">
                <div class="text-value-div text-color">
                  <label>{{ shipTo ? shipTo.vat_number : null }}</label>
                </div>
              </v-col>
              <v-col class="d-flex pb-0" cols="3">
                <v-card-text class="text-start text-sm-end align-self-center">貿易条件(Terms of Trade):</v-card-text>
              </v-col>
              <v-col class="d-flex pb-0 border-right" cols="3">
                <div class="text-value-div text-color">
                  <label>{{ (shippingTerms.find((row) => row.code === shippingTerm) || {}).name }}</label>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="border-bottom">
              <v-col class="d-flex pb-0" cols="3">
                <v-card-text class="text-start text-sm-end align-self-center">出荷日:</v-card-text>
              </v-col>
              <v-col class="d-flex pb-0" cols="3">
                <div class="text-value-div text-color">
                  <label>{{ scheduledShippingDate }}</label>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </div>
      <v-card-actions class="d-flex justify-end">
        <v-btn color="primary" :disabled="disableBtn || loading" :loading="loading" @click="handleSend"
          >チェック完了
        </v-btn>
        <v-btn color="orange darken-1" text :disabled="loading" @click="handleClose"> キャンセル </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
      required: true,
    },
    memberInfo: {
      type: Object,
      required: true,
    },
    showConfirm: {
      type: Boolean,
      default: false,
    },
    methodFlag: {
      default: 1,
    },
  },
  emits: ['change:radio', 'close', 'click:confirm'],
  data() {
    return {
      shippingTerm: '',
      shipToCompanyName: '',
      taxPayerAccNo: '',
      shippingFeePayerAcc: '',
      shippingFeePayerCompanyName: '',
      taxPayerCompanyName: '',
      vatNumber: '',
      courierCode: 1,
      couriers: [
        { code: 1, name: 'FedEx' },
        { code: 2, name: 'UPS' },
        { code: 3, name: 'DHL' },
      ],
      shippingFeePayer: 0,
      deliveryType: '1',
      country: null,
      state: null,
      city: '',
      zipCode: '',
      addr1: '',
      addr2: '',
      tel: '',
      personInCharge: '',
      insuranceItems: [
        { code: 0, name: '補償なし' },
        { code: 1, name: '補償あり' },
      ],
      insurance: null,
      insurancePrice: 0,
      accountHolder: null,
      accountHolderTypes: [
        { code: 0, holder: '荷受人' },
        { code: 1, holder: '第三者' },
      ],
      shipToTypeNo: this.memberInfo?.default_ship_to ?? 1,
      validate: false,
      sm1: 3,
      sm2: 9,
      percent: 100,
    };
  },
  computed: {
    ...mapGetters({
      courierCountriesStates: 'constantMaster/courierCountriesStates',
      courierDeliveryTypes: 'constantMaster/courierDeliveryTypes',
      shippingTerms: 'constantMaster/shippingTerms',
      shipTo: 'shukkaShoruiSakusei/shipTo',
      loading: 'ui/loading',
    }),
    scheduledShippingDate() {
      return this.memberInfo ? moment(this.memberInfo.scheduled_shipping_date).format('YYYY-MM-DD') : '';
    },
    shippingFeePayers() {
      if (this.shipToTypeNo === 5) {
        return [
          { code: 0, name: '荷送人' },
          { code: 1, name: '荷受人' },
          { code: 2, name: '第三者' },
        ];
      }
      if (this.shipTo && this.shipTo.account_holder === 0) {
        return [
          { code: 0, name: '荷送人' },
          { code: 1, name: '荷受人' },
        ];
      } else if (this.shipTo && this.shipTo.accountHolder === 1) {
        return [
          { code: 0, name: '荷送人' },
          { code: 2, name: '第三者' },
        ];
      } else
        return [
          { code: 0, name: '荷送人' },
          { code: 1, name: '荷受人' },
          { code: 2, name: '第三者' },
        ];
    },
    courierCountries() {
      return this.courierCountriesStates
        ? this.courierCountriesStates[this.courierCode ?? 1].countryLists.map(({ name }) => name)
        : [];
    },
    accountHolderAcct() {
      const row = this.accountHolderTypes.find((row) => row.code === this.accountHolder);
      if (!row) return '';
      if (row.holder === '荷受人') return 'Receiver';
      if (row.holder === '第三者') return 'Third Party';
      return '';
    },
    taxPayerAcct() {
      const row = this.accountHolderTypes.find((row) => row.code === this.accountHolder);
      if (!row) return '';
      if (row.holder === '荷受人') return 'Receiver Will Pay';
      if (row.holder === '第三者') return this.shipTo.tax_payer_company_name;
      return '';
    },
    dhlShippingFeePayer() {
      const row = this.shippingFeePayers.find((row) => row.code === this.shippingFeePayer);
      if (!row) return '';
      if (row.name === '荷受人') return 'Receiver';
      if (row.name === '第三者') return 'Third Party';
      return '';
    },
    uspShippingFeePayer() {
      return this.shipTo.shipping_fee_payer_type === 0
        ? 'P/P'
        : this.shipTo.shipping_fee_payer_type === 1 || this.shipTo.shipping_fee_payer_type === 2
          ? 'F/C'
          : '';
    },
    upsTaxPayerAcct() {
      return this.shipTo.tax_payer_type === 0 ? 'RECEIVER' : this.shipTo.tax_payer_type === 1 ? 'TPR' : '';
    },
    courierStates() {
      return (
        this.courierCode &&
        this.courierCountriesStates?.[this.courierCode].stateLists.map(({ country, name }) => {
          if (country === this.countryCode) {
            return name;
          }
        })
      );
    },
    computedCountryName() {
      const country =
        this.courierCountriesStates && this.courierCode
          ? this.courierCountriesStates[this.courierCode > 3 ? 1 : this.courierCode].countryLists.find(
              ({ code }) => code === this.countryCode,
            )
          : null;
      return country ? country.name : country;
    },
    computedStateName() {
      if (!this.courierCountriesStates || !this.courierCode || !this.countryCode) return '';
      const state = this.courierCountriesStates[this.courierCode].stateLists.find(({ country, code }) => {
        if (country !== this.countryCode) return false;
        return code === this.stateCode;
      });
      return state?.name;
    },
    countryCode() {
      if (this.shipTo && this.shipToTypeNo !== 5) {
        return this.shipTo ? this.shipTo.ship_to_country_code : null;
      } else if (this.shipTo && this.shipToTypeNo === 5) {
        const countryInfo =
          this.courierCountriesStates && this.courierCode && this.courierCountriesStates[this.courierCode]
            ? this.courierCountriesStates[this.courierCode].countryLists.find(({ name }) => name === this.country)
            : null;
        return countryInfo ? countryInfo.code || this.shipTo.ship_to_country_code : this.shipTo.ship_to_country_code;
      }
      return null;
    },
    stateCode() {
      if (this.shipTo && this.shipToTypeNo !== 5) {
        return this.shipTo?.ship_to_state_code;
      } else if (this.shipTo && this.shipToTypeNo === 5) {
        const stateInfo =
          this.courierCountriesStates && this.courierCode
            ? this.courierCountriesStates[this.courierCode].stateLists.find(({ name }) => name === this.state)
            : null;
        return stateInfo && this.state
          ? stateInfo.code
          : this.shipTo.ship_to_country_code !== this.countryCode && !this.state
            ? stateInfo //probably returning null
            : this.shipTo.ship_to_state_code;
      }
      return null;
    },
    region() {
      const countryInfo =
        this.courierCountriesStates && this.courierCode && this.courierCountriesStates[this.courierCode]
          ? this.courierCountriesStates[this.courierCode].countryLists.find(({ code }) => code === this.countryCode)
          : null;
      return countryInfo ? countryInfo.region : '99';
    },
    memberId() {
      return this.memberInfo?.member_id ?? null;
    },
    shipToType() {
      return this.memberInfo?.ship_to_type ?? 1;
    },
    titleClass() {
      return {
        red: this.error,
        primary: !this.error && !this.success,
        success: this.success,
      };
    },
    confirmBtnLabel() {
      return this.error ? '閉じる' : this.modalConfirmBtnLabel;
    },
    disabled() {
      if (this.shipToTypeNo !== 5 && !this.showConfirm) {
        return true;
      }
      if (this.showConfirm) {
        return true;
      }
      return false;
    },
    disableBtn() {
      return this.computedCountryName || this.country ? false : true;
    },
    hasStates() {
      return this.courierStates ? this.courierStates.map((x) => x).filter((x) => x).length > 0 : false;
    },
    upsInvoiceNo() {
      return this.memberInfo
        ? [...new Set(this.memberInfo.matter_no_list.split(',').map((x) => x.substr(4, 6)))].join()
        : '';
    },
  },
  watch: {
    courierCode(val) {
      if (val > 3) this.courierCode = 1;
    },
    showModal(val) {
      if (val) {
        this.shipToTypeNo = this.shipToType ?? 1;
        this.apiGetShipTo([
          // this.shipToTypeNo,
          5, //get info from t_packing
          this.memberId,
          this.memberInfo.shipping_seq_number,
          this.memberInfo.matter_no_list,
        ]);
      }
      if (!val) {
        this.resetShipTo();
        this.country = null;
        this.state = null;
        this.shipToTypeNo = this.memberInfo?.default_ship_to ?? 1;
      }
    },
    shipTo(val) {
      if (val) {
        this.updateAddr();
      }
    },
  },
  methods: {
    ...mapActions({
      apiGetShipTo: 'api/getShipTo',
      resetShipTo: 'shukkaShoruiSakusei/reSetShipTo',
    }),
    updateAddr() {
      this.loadInitialShipInfo();
      this.country = this.computedCountryName;
      this.state = this.computedStateName;
      this.insurancePrice = this.shipTo.insurance_price;
    },
    loadInitialShipInfo() {
      this.deliveryType = this.shipTo.delivery_type ? this.shipTo.delivery_type : '1';
      this.courierCode = this.shipTo.courier_code;
      this.shipToCompanyName = this.shipTo.ship_to_company_name;
      this.city = this.shipTo.ship_to_city;
      this.zipCode = this.shipTo.ship_to_zip_code;
      this.addr1 = this.shipTo.ship_to_address1;
      this.addr2 = this.shipTo.ship_to_address2;
      this.tel = this.shipTo.telephone_number;
      this.insurance = this.shipTo.insurance;
      this.accountHolder = this.shipTo.account_holder;
      this.personInCharge = this.shipTo.name;
      this.shippingFeePayer = this.memberInfo.shipping_fee_payer;
      this.shippingTerm = Number(this.memberInfo.shipping_terms);
      this.shippingFeePayerAcc =
        this.memberInfo.shipping_fee_payer === 0
          ? 'Aucnet Acc'
          : this.shipToTypeNo !== 5
            ? this.shipTo.courier_account
            : this.shipTo.shipping_fee_paying_account;
      this.shippingFeePayerCompanyName =
        this.memberInfo.shipping_fee_payer === 0
          ? 'Aucnet Inc.'
          : this.shipToTypeNo !== 5
            ? this.shipTo.account_holder_company_name
            : this.shipTo.shipping_fee_payer_company_name;
      this.taxPayerAccNo = this.shipTo.courier_account;
      this.taxPayerCompanyName =
        this.shipTo.account_holder === 0 ? this.shipTo.ship_to_company_name : this.shipTo.account_holder_company_name;
    },
    handleClose() {
      this.$emit('close');
    },
    handleSend() {
      this.$emit('click:send', this.memberInfo);
    },
  },
};
</script>
<style scoped>
div.v-card__text {
  white-space: pre-wrap;
}
div.text-value-div {
  padding: 16px;
}
.text-color {
  color: red;
}
.text-left {
  text-align: left !important;
}
.border-right {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
.border-bottom {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>
